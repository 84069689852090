/* We need to add display:inline in order to align the '>>' of the 'read more' link */
.post-excerpt p {
	display:inline;
}

// Import partials from `sass_dir` (defaults to `_sass`)
@import
	"syntax"
;
.image-gallery-index a img,
.product-grid a img,
.product-gallery a img,
.image-gallery a img{
-webkit-filter: grayscale(100%) brightness(116%) contrast(83%) opacity(70%);
filter: grayscale(100%) brightness(116%) contrast(83%) opacity(70%);
-webkit-transition: all .3s ease;
-moz-transition: all .3s ease;
-o-transition: all .3s ease;
-ms-transition: all .3s ease;
transition: all .3s ease;
}
#instafeed a:hover img,
.image-gallery-index a:hover img,
.product-grid a:hover img,
.product-gallery a:hover img,
.image-gallery a:hover img{-webkit-filter: grayscale(0%) brightness(100%) contrast(100%) opacity(100%); filter: grayscale(0%) brightness(100%) contrast(100%) opacity(100%);}
.navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {background: none;}
.btn-primary {background: #d9230f!important; border: 1px solid #d9230f!important; font-weight: bold; color: rgba(255,255,255,0.75)!important;}
.btn-primary:hover {color: rgba(255,255,255,0.9)!important;}
.btn {
    padding: 5px 10px!important;
    -webkit-box-shadow: none!important;
    box-shadow: none!important;
}
.btn-secondary {
    border: 1px solid #777777!important; color: #777777!important;
}
.btn-lg {padding: 8px 18px !important;}
.image-gallery-index a,
.image-gallery a {color: silver!important;
    -webkit-transition: color .3s ease;
    -moz-transition: color .3s ease;
    -o-transition: color .3s ease;
    -ms-transition: color .3s ease;
    transition: color .3s ease;
}
.image-gallery-index a:hover,
.image-gallery a:hover {color: #777!important;}
lightbox {width: 100%; height: 100%; position: fixed; top: 0; left: 0; background: rgba(0,0,0,0.85); z-index: 9999999; line-height: 0; cursor: pointer;}
.lightbox .img {
	position: relative; 
	top: 50%;
	left: 50%;
	-ms-transform: translateX(-50%) translateY(-50%);
	-webkit-transform: translate(-50%,-50%);
	transform: translate(-50%,-50%);
	max-width: 100%;
	max-height: 100%;
}
.lightbox .img img {opacity: 0; pointer-events: none; width: auto;}
@media screen and (min-width: 1200px) {
    .lightbox .img {
		max-width: 1200px;
    }
}
@media screen and (min-height: 1200px) {
    .lightbox img {
		max-height: 1200px;
	}
}
.lightbox span {display: block; position: fixed; bottom: 13px; height: 1.5em; line-height: 1.4em; width: 100%; text-align: center; color: white; text-shadow:
    -1px -1px 0 #000,
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000;  
}

.lightbox span {display: none;}


.lightbox span {display: block;}

.lightbox .videoWrapperContainer {
	position: relative; 
	top: 50%;
	left: 50%;
	-ms-transform: translateX(-50%) translateY(-50%);
	-webkit-transform: translate(-50%,-50%);
	transform: translate(-50%,-50%);
	max-width: 900px;
	max-height: 100%;
}
.lightbox .videoWrapperContainer .videoWrapper {
	height: 0;
	line-height: 0;
	margin: 0;
	padding: 0;
	position: relative;
	padding-bottom: 56.333%; /* custom */
	background: black;
} 
.lightbox .videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 0;
	display: block;
}   
.lightbox #prev, .lightbox #next {height: 50px; line-height: 36px; display: none; margin-top: -25px; position: fixed; top: 50%; padding: 0 15px; cursor: pointer; text-decoration: none; z-index: 99; color: white; font-size: 60px;}
.lightbox.gallery #prev, .lightbox.gallery #next {display: block;}
.lightbox #prev {left: 0;}
.lightbox #next {right: 0;}
.lightbox #close {height: 50px; width: 50px; position: fixed; cursor: pointer; text-decoration: none; z-index: 99; right: 0; top: 0;}
.lightbox #close:after, .lightbox #close:before {position: absolute; margin-top: 22px; margin-left: 14px; content: ""; height: 3px; background: white; width: 23px;
-webkit-transform-origin: 50% 50%;
-moz-transform-origin: 50% 50%;
-o-transform-origin: 50% 50%;
transform-origin: 50% 50%;
/* Safari */
-webkit-transform: rotate(-45deg);
/* Firefox */
-moz-transform: rotate(-45deg);
/* IE */
-ms-transform: rotate(-45deg);
/* Opera */
-o-transform: rotate(-45deg);
}
.lightbox #close:after {
/* Safari */
-webkit-transform: rotate(45deg);
/* Firefox */
-moz-transform: rotate(45deg);
/* IE */
-ms-transform: rotate(45deg);
/* Opera */
-o-transform: rotate(45deg);
}
.lightbox, .lightbox * {
    -webkit-user-select: none;  
    -moz-user-select: none;    
    -ms-user-select: none;      
    user-select: none;
}